// Base
@import 'base/reset';
@import 'base/fonts';

// Abstracts
@import 'abstracts/colors';
@import 'abstracts/mixins';

// Components
@import 'components/header';
@import 'components/hero';
@import 'components/about';
@import 'components/projects';
@import 'components/contact';
@import 'components/footer';

body {
  @include font-primary-regular;
  color: $color-black;
  font-size: 16px;
}
