.footer {
  background-color: $color-dark-gray;
  color: $color-white;
  padding: 30px;

  &__container {
    text-align: center;
  }

  &__logo {
    font-size: 21px;
    font-weight: 300;
    letter-spacing: 3px;
    text-transform: uppercase;

    strong {
      border-bottom: 3px solid $color-white;
      font-weight: 900;
      padding-bottom: 4px;
    }
  }

  @include breakpoint-tablet {
    &__container {
      text-align: left;
    }
  }
}
