@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

$font-primary: 'Lato', sans-serif;

@mixin font-primary-light {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 300;
}

@mixin font-primary-regular {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 400;
}

@mixin font-primary-bold {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 700;
}

@mixin font-primary-black {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 900;
}
