.hero {
  background: linear-gradient(rgba($color-black, .4), rgba($color-black, .4)), url('../../images/hero-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 95vh;

  &__container {
    align-items: center;
    display: flex;
    height: inherit;
    justify-content: center;
  }

  &__title {
    @include font-primary-bold;
    color: $color-white;
    font-size: 45px;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;
  }

  &__title-caption {
    @include font-primary-light;
    border-top: 2px solid $color-white;
    display: block;
    margin-top: 8px;
    padding-top: 8px;
  }

  @include breakpoint-tablet {
    &__title {
      font-size: 75px;
    }
  }
}
