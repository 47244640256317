$projects: 'yoga-para-mi' 'time-in-new-york' 'roofing-cost-calculator'
'tking' 'dash' 'dia-de-muertos';

.projects {
  padding: 16px 0;

  &__item {
    align-items: center;
    display: inline-flex;
    height: 50vh;
    justify-content: center;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 100;

    &:hover {
      .projects__item-img {
        filter: brightness(.6);
      }

      .projects__item-link {
        transform: scale(1.1);
      }

      .projects__item-border {
        &--top,
        &--bottom {
          height: 1px;
          left: 10%;
          width: 80%;
        }

        &--left,
        &--right {
          height: 80%;
          top: 10%;
          width: 1px;
        }
      }
    }
  }

  &__item-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    transition: all .4s ease;
    width: 100%;

    @each $item in $projects {
      &--#{$item} {
        background-image: linear-gradient(rgba($color-black, .2), rgba($color-black, .2)), url('../../images/projects/#{$item}.jpg');
      }
    }
  }

  &__item-link {
    color: $color-white;
    text-align: center;
    text-transform: uppercase;
    transition: all .2s ease-in-out;
    width: 100%;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  &__item-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__item-type {
    font-size: 18px;
  }

  &__item-border {
    background: $color-white;
    height: 0;
    position: absolute;
    transition: all .5s ease;
    width: 0;

    &--top {
      left: 50%;
      top: 10%;
    }

    &--bottom {
      bottom: 10%;
      left: 50%;
    }

    &--left {
      left: 10%;
      top: 50%;
    }

    &--right {
      right: 10%;
      top: 50%;
    }
  }

  @include breakpoint-desktop {
    &__item {
      height: 60vh;

      &:nth-child(odd) {
        margin-right: 1%;
      }
    }

    &__item--big {
      width: 59%;
    }

    &__item--small {
      width: 40%;
    }

		&__item-title {
			font-size: 32px;
		}

		&__item-type {
			font-size: 24px;
		}
  }
}
