.contact {
  align-items: center;
  background-color: $color-off-white;
  display: flex;
  justify-content: center;

  &__container {
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 $color-light-gray;
    margin: 30px 0;
    padding: 40px 25px;
    width: 90%;
  }

  &__title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
  }

  &__desc {
    font-size: 21px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;

    a {
      border-bottom: 2px solid $color-black;
      color: $color-black;
      margin-bottom: 4px;
    }
  }

  &__social {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 40px auto 0;
    width: 80%;
  }

  &__social-icon {
    width: 40px;
  }

  @include breakpoint-tablet {
    &__social {
      width: 40%;
    }
  }

  @include breakpoint-desktop {
    &__container {
      margin: 50px 0;
      padding: 100px 0;
      width: 80%;
    }

    &__title {
      font-size: 36px;
    }

    &__desc {
      font-size: 28px;
      line-height: 42px;
    }

    &__social {
      width: 20%;
    }
  }
}
