.header {
  color: $color-white;
  padding: 20px;
  position: absolute;
  transition: .5s;
  width: 100%;

  &--open {
    background-color: $color-white;
    color: $color-black;

    .header__logo--strong {
      border-color: $color-black;
    }

    .header__menu-bar {
      background-color: $color-black;

      &--1 {
        transform: rotate(-45deg) translate(-5px, 7px);
      }

      &--2 {
        opacity: 0;
      }

      &--3 {
        transform: rotate(45deg) translate(-4px, -7px);
      }
    }

    .header__nav {
      display: block;

      a {
        color: $color-black;
      }
    }
  }

  &__logo {
    @include font-primary-light;
    letter-spacing: 3px;
    text-transform: uppercase;

    &--strong {
      @include font-primary-black;
      border-bottom: 3px solid $color-white;
      padding-bottom: 4px;
    }
  }

  &__menu {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 15px;
  }

  &__menu-bar {
    background-color: $color-white;
    display: block;
    height: 3px;
    margin: 5px 0;
    transition: .5s;
    width: 25px;
  }

  &__nav {
    display: none;
    padding-top: 20px;
    text-align: center;

    a {
      color: $color-white;
      display: block;
      line-height: 24px;
      margin: 10px 0;
      text-transform: uppercase;
    }
  }

  @include breakpoint-tablet {
    &__container {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &__logo {
      font-size: 20px;
    }

    &__menu {
      display: none;
    }

    &__nav {
      display: block;
      padding: 0;

      a {
        display: inline-block;
        margin: 0 8px;

        &:hover,
        &:active,
        &:focus {
          box-shadow: 0 3px 0 $color-white;
        }
      }
    }
  }

  @include breakpoint-desktop {
    padding: 20px 30px 0;

    &__logo {
      font-size: 24px;
    }
  }
}
