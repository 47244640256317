.about {
  &__container {
    padding: 50px;
  }

  &__title {
    @include font-primary-bold;
    font-size: 60px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  &__text {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      margin-left: 5px;
    }
  }

  &__sep {
    background-color: $color-black;
    height: 3px;
    margin-bottom: 20px;
    width: 10%;
  }

  @include breakpoint-tablet {
    &__container {
      display: flex;
      justify-content: center;
    }

    &__desc {
      padding-left: 30px;
    }
  }

  @include breakpoint-desktop {
    &__container {
      margin: 0 auto;
      padding: 100px;
      width: 65%;
    }

    &__desc {
      padding-left: 50px;
    }
  }
}
